/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
	// All pages
	'common': {
	  init: function() {
		// JavaScript to be fired on all pages

		/* ========================================================================
		* check scroll position
		* ======================================================================== */

		$(window).bind('scroll', function() {
			if ($(window).scrollTop() > 125) {
				$('.navbar').addClass('fixed').delay(100).queue(function(){
					$(this).addClass("top").dequeue();
				});
				var height = $('.navbar').outerHeight();
				$('body').css('padding-top', height + 'px');
			}
			else {
				$('.navbar').removeClass('fixed top');
				$('body').css('padding-top', "0");
			}
		});

		$('.navbar-toggle').on('click', function(){
			$('#menu-main-menu').toggle();
		});

		$('#menu-main-menu a').on('click', function(){
			if ($(window).width() < 768) {
				$('#menu-main-menu').hide();
			}
		});

		$('.view').on('click', function(){
			$(this).next().toggle();
			$(this).parent().toggleClass('open');
		});
	  },
	  finalize: function() {
		// JavaScript to be fired on all pages, after page specific JS is fired
	  }
	},
	// Home page
	'home': {
	  init: function() {
		// JavaScript to be fired on the home page
	  },
	  finalize: function() {
		// JavaScript to be fired on the home page, after the init JS
	  }
	},
	// About us page, note the change from about-us to about_us.
	'promo': {
	  init: function() {
		// JavaScript to be fired on the about us page
	  },
	  finalize: function() {
		// JavaScript to be fired on the home page, after the init JS
		$( "input[name*='PPASS']" ).val(guid());
	  }
	}
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
	fire: function(func, funcname, args) {
	  var fire;
	  var namespace = Sage;
	  funcname = (funcname === undefined) ? 'init' : funcname;
	  fire = func !== '';
	  fire = fire && namespace[func];
	  fire = fire && typeof namespace[func][funcname] === 'function';

	  if (fire) {
		namespace[func][funcname](args);
	  }
	},
	loadEvents: function() {
	  // Fire common init JS
	  UTIL.fire('common');

	  // Fire page-specific init JS, and then finalize JS
	  $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
		UTIL.fire(classnm);
		UTIL.fire(classnm, 'finalize');
	  });

	  // Fire common finalize JS
	  UTIL.fire('common', 'finalize');
	}
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

// create ID
function guid() {
	var code = s4() + '-' + s4() + '-' + s4();
	return code.toUpperCase();
}
  
function s4() {
	return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
}